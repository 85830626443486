import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import TemplateWrapper from '../components/layout';

export default class Success extends Component {

  render() {

    return (
      <TemplateWrapper>
      <div className="article-content">
        <Helmet>
          <title>Наращивание волос в Москве - качественно и недорого</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>

        <br/><br/>
            <br/><br/>
            <br/><br/>
            
            <h1 className="hm-title-1 text-center m-0">
                Спасибо! <br />
            Наш менеджер свяжется с Вами!    
            </h1>
            <br/><br/>
        
            <br/><br/>
            <br/><br/>
            <br/><br/>


      
      </div>
      </TemplateWrapper>
    );
  }
}